.survey-container {
    background-color: white;
    border-radius: 20px;
}

.sv_main {
    font-family: "Stem", "Helvetica Neue", Helvetica, Arial, sans-serif, "Helvetica Neue", Arial, sans-serif;
    font-size: 16px; 
    font-weight: 500; 
    color: black ;
    font-weight: normal;
    letter-spacing: 1px;
    width: 700px;
    min-width: 400px;
    max-width: 800px;
}

.sv_container  {
    color: black !important;
}

.sv_body {
    border-top: none !important;
    padding: 0 !important;
}

.sv_row {
    border-bottom: none !important;
    background-color: white !important;
    
}


label {
    font-weight: normal !important;
}

h1, h2, h3, h4, h5, h6 {
    font-weight:500 !important;
}

.sv_progress_bar {
    background-color: red !important;
    height: 12px !important;
    margin-bottom: 20px;
    border-radius: 20px;
}

.sv_progress {
    background-color: gray;
    height: 12px !important;
    border-radius: 20px;
}

.sv_main input[type="button"], .sv_main button {
    background-color: red;
    font-size: 20px;
    border-radius: 5px;
}

.sv_main input[type="button"]:hover, .sv_main button:hover {
    background-color: darkred;
    
}

.sv_main {
    padding: 0px !important;
}



.sv_p_root .sv_qcbc {
    line-height: 1.4em !important;
}

.sv_p_title {
    padding-left: 0px !important;
    font-size: 22px !important;
}

.sv_p_container {
        width: 600px;
}

.sv_q_checkbox {
    margin-top: 20px;
}

.sv_q .sv_qstn {
    padding: 0.5em 1em 1.5em 0em !important;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q {
    padding: 0}
    

.sv_q_text_root:focus {
    border: 1px solid red !important;
}

.sv_q_text_root {
    color: black !important;
    border-radius: 5px;
    height: 50px !important;
}

.sv_q_erbox .sv_qstn_error_top {
    font-size: 5px !important;
}

.sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
   
    font-size: 12px;
    line-height: 16px;
    border: 0px !important;
}


@media screen and (max-width: 615px) {
    .sv_main {
        font-size: 14px; 
        width: 300px;
        min-width: 375px;
    }

    .sv_main input[type="button"], .sv_main button {
        background-color: red;
        font-size: 14px;
        border-radius: 5px;
    }

    .sv_q_text_root {
        color: black !important;
        border-radius: 5px;
        height: 40px !important;
    }
}

@media screen and (max-width: 400px) {
    .sv_main {
        font-size: 14px; 
        width: 300px;
        min-width: 300px;
    }
}