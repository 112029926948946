.body {
  display: block;
  max-width: 1300px;
  margin: 0px auto 20px 0px;
  padding: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f0f0f0;
  font-family: Stem, sans-serif;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 1px;
}

.columns {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.25fr 1fr;
  grid-template-columns: 0.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.sidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.content {
  padding: 49px 82px 49px 94px;
  border: 1px none #000;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 1px 1px 50px 0 rgba(0, 0, 0, 0.08);
}

.year {
  display: -ms-grid;
  display: grid;
  margin-bottom: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.25fr 2fr;
  grid-template-columns: 0.25fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 2px solid #e0e0e0;
}

.year-number-block {
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.age {
  font-size: 16px;
  line-height: 17px;
  font-weight: 500;
  letter-spacing: 1.1px;
}

.year-number-text {
  color: #6e6e6e;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1px;
}

.test-block {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 15px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.test-block:hover {
  border-radius: 8px;
  background-color: #f0f0f0;
  box-shadow: 0 8px 16px -18px #000;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.test-block.important {
  border-radius: 11px;
  background-color: rgba(255, 40, 40, 0.12);
}

.test-text {
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
}

.test-detail {
  display: inline-block;
  margin-right: 15px;
  font-weight: 500;
}

.detail-container {
  display: inline-block;
}

.dot {
  margin-right: 5px;
}

.heading {
  margin-bottom: 25px;
  font-size: 32px;
  line-height: 34px;
}

.heading.bold {
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
}

.logo {
  margin-bottom: 0px;
}

.body-text {
  margin-top: 20px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.pin-container {
  margin-top: 25px;
  padding: 20px 10px 20px 15px;
  border: 2px solid #000;
  border-radius: 10px;
}

.pin {
  font-size: 21px;
  font-weight: 700;
  text-align: left;
}

.home-container {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.body-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Stem, sans-serif;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.text-field {
  display: inline-block;
  width: 150px;
  height: 60px;
  margin-bottom: 0px;
  float: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 22px;
  font-weight: 700;
}

.field-label {
  display: inline-block;
}

.submit-button {
  padding-right: 20px;
  padding-left: 20px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 18px;
  font-weight: 500;
}

.submit-button:hover {
  background-color: #0481ee;
}

.home-content {
  display: -ms-grid;
  display: grid;
  width: 700px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.b {
  font-weight: 500;
}

.lead {
  font-size: 18px;
}

.lead.b {
  margin-bottom: 10px;
  line-height: 22px;
}

.cta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #ec3847;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 18px;
  font-weight: 500;
}

.cta:hover {
  box-shadow: 0 18px 20px -15px #c71818;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.logo-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.logo-container-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.survey-container {
  max-width: 800px;
  min-height: 100px;
  margin: 20px;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
}

.lottie-animation {
  width: 100px;
}

.div-block-3 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
}

.text-block {
  margin: 20px;
  font-family: Stem, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
}

.loading-container {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.everyyear {
  display: -ms-grid;
  display: grid;
  margin: 0px 0px 0px -20px;
  padding: 20px 20px 40px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.25fr 2fr;
  grid-template-columns: 0.25fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 0px solid #e0e0e0;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.disclamer {
  padding-right: 115px;
  padding-left: 14px;
}

.fixeddiv {
  position: fixed;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 16%;
  max-width: 200px;
  margin: 45px;
  padding-top: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.disclaimer {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 16px;
}

.text-block-2 {
  margin-top: 10px;
}

.print-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  padding: 20px 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
  color: #1a1a1a;
  text-decoration: none;
  cursor: pointer;
}

.print-button:hover {
  background-color: #e9e9e9;
}

.image {
  margin-right: 15px;
}

.text-block-3 {
  font-family: Stem, sans-serif;
  font-weight: 500;
}

.cta-button {
  margin-top: 40px;
  border-style: none;
  border-width: 0px;
  border-color: #ffe500;
  border-radius: 8px;
  background-color: #fd0;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
  cursor: pointer;
}

.cta-button:hover {
  box-shadow: -2px 12px 50px -2px #ffda22;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.cta-header {
  padding: 10px 20px 20px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  text-decoration: none;
}

.div-block-5 {
  display: -ms-grid;
  display: grid;
  margin-left: -30px;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 0.5fr;
  grid-template-columns: 1fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-6 {
  margin-top: -27px;
}

.image-2 {
  margin-top: 15px;
  padding: 14px;
}

.heading-landing {
  max-width: 700px;
  margin-bottom: 40px;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  font-size: 48px;
  line-height: 52px;
}

.big-lead {
  width: 100%;
  max-width: 600px;
  font-size: 22px;
  line-height: 30px;
}

.big-lead.b {
  margin-bottom: 10px;
  line-height: 22px;
}

.report-block {
  display: none;
  margin-top: 60px;
  margin-bottom: 40px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 0.5fr;
  grid-template-columns: 1fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-3 {
  margin-top: -30px;
  margin-left: -55px;
}

.div-block-7 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
}

.report-features {
  display: -ms-grid;
  display: grid;
  height: 50px;
  margin-bottom: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.25fr 1fr;
  grid-template-columns: 0.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.emoji {
  padding-top: 15px;
  font-size: 40px;
  text-align: right;
}

.feature-text {
  font-size: 20px;
  line-height: 22px;
}

.div-block-8 {
  width: 100%;
  margin-left: -50px;
}

.landing-body-text {
  max-width: 700px;
  font-size: 17px;
  line-height: 24px;
}

.landing-body-text.white {
  color: #fff;
}

.landing-div {
  margin-top: 30px;
  margin-bottom: 0px;
}

.landing-div.grid {
  display: -ms-grid;
  display: grid;
  margin-bottom: 80px;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 32px;
  -ms-grid-columns: 0.5fr 0.75fr 0.75fr;
  grid-template-columns: 0.5fr 0.75fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.landing-div.additionaltopmargin {
  margin-top: 101px;
}

.attention-block {
  display: -ms-grid;
  display: grid;
  margin-top: 80px;
  margin-bottom: 7px;
  padding: 68px 86px 68px 68px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #9b9b9b;
  border-radius: 10px;
  background-color: #fff;
}

.clinic-logo {
  margin-bottom: 19px;
}

.small-text {
  line-height: 19px;
}

.small-text.gray {
  color: #a0a0a0;
}

.privacy {
  display: -ms-grid;
  display: grid;
  margin-top: 35px;
  margin-bottom: 69px;
  padding: 40px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.25fr 1fr;
  grid-template-columns: 0.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 10px;
  background-color: #35363a;
}

.login {
  margin-top: 41px;
}

.submit-button-2 {
  width: 50%;
  height: 50px;
  padding: 10px 5px;
  border-radius: 2px 10px 10px 2px;
  background-color: #000;
  font-size: 16px;
}

.submit-button-2:hover {
  background-color: #313131;
}

.support-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding: 0px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.cta-mobile {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: none;
  height: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffe500;
  color: #000;
  font-size: 26px;
  text-decoration: none;
}

.desc {
  margin-top: 10px;
}

.text-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-field-2 {
  width: 50%;
  height: 50px;
  float: left;
  border: 2px solid #000;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 25px;
  line-height: 25px;
}

.field-label-2 {
  margin-bottom: 15px;
  font-weight: 500;
}

.smalltext {
  font-size: 9px;
  line-height: 10px;
  font-weight: 400;
}

.div-block-9 {
  width: 500px;
  height: 500px;
}

.form-block {
  margin-bottom: 0px;
}

.cta-button-result {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  padding: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fd0;
  color: #0f0f0f;
  text-align: center;
  text-decoration: none;
}

.cta-button-result-text {
  position: static;
  text-decoration: none;
  white-space: normal;
  -o-object-fit: fill;
  object-fit: fill;
}

.navigation {
  display: -ms-grid;
  display: grid;
  margin-bottom: 92px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.navlink {
  padding: 14px;
  border: 3px solid #000;
  border-radius: 10px;
  color: #292929;
  text-decoration: none;
}

.navlink:hover {
  background-color: #e6e6e6;
}

.navtext {
  border-radius: 10px;
  font-size: 16px;
}

.leadgrid {
  display: -ms-grid;
  display: grid;
  margin-top: 39px;
  margin-bottom: 51px;
  grid-auto-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 0.5fr;
  grid-template-columns: 1fr 0.5fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.text-block-5 {
  font-size: 12px;
}

.featuresblock {
  display: -ms-grid;
  display: grid;
  margin-bottom: 60px;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 26px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.featurecontainer {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.25fr 1fr;
  grid-template-columns: 0.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.featureheader {
  padding-bottom: 7px;
  font-size: 18px;
  line-height: 20px;
}

.salepromo {
  text-align: center;
}

.pricetag {
  margin-top: 185px;
}

.image-4 {
  margin-top: -221px;
  margin-left: 400px;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
}

.image-4:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.div-block-13 {
  margin-top: -341px;
  margin-left: 77px;
  text-align: left;
}

.payment {
  display: -ms-grid;
  display: grid;
  margin-top: 30px;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.5fr 1fr;
  grid-template-columns: 0.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 10px;
  background-color: #fef6ff;
}

.image-5 {
  width: 150px;
  height: 150px;
}

.paymenticon {
  margin-right: 24px;
}

.div-block-14 {
  padding: 31px 61px 31px 31px;
  text-align: left;
}

.div-block-15 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.questionblock {
  padding-top: 31px;
  padding-bottom: 85px;
  border-top: 3px none #000;
  border-bottom: 3px solid #000;
}

.image-6 {
  margin-top: 50px;
  margin-bottom: 20px;
}

.div-block-16 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.4fr 1fr;
  grid-template-columns: 0.4fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-7 {
  margin-top: 25px;
  margin-bottom: -50px;
  margin-left: -57px;
}

.image-8 {
  margin-top: 26px;
  margin-bottom: 39px;
}

.image-9 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.importanceblock {
  display: -ms-grid;
  display: grid;
  margin-top: 20px;
  grid-auto-columns: 1fr;
  grid-column-gap: 33px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-10 {
  margin-bottom: 10px;
  margin-left: -10px;
}

.image-11 {
  margin-bottom: 15px;
}

.desclamerblock {
  display: -ms-grid;
  display: grid;
  margin-top: 20px;
  grid-auto-columns: 1fr;
  grid-column-gap: 33px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.background-video {
  z-index: 99999;
  margin-bottom: 30px;
}

.div-block-17 {
  position: static;
  display: -ms-grid;
  display: grid;
  width: 700px;
  margin: auto;
  padding: 14px 16px 47px 47px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.5fr 1.5fr 0.25fr;
  grid-template-columns: 0.5fr 1.5fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 19px 50px -17px #000;
}

.popupoverlay {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.69);
  color: rgba(0, 0, 0, 0.83);
}

.popupheader {
  margin-top: 6px;
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
}

.testdatailpopup {
  display: inline-block;
  margin-right: 15px;
  font-weight: 500;
}

.div-block-18 {
  padding-top: 35px;
}

.agecard {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1.1px;
}

.div-block-19 {
  padding-top: 27px;
}

.paragraph {
  margin-top: 35px;
}

.cardimportance {
  margin-top: 20px;
}

.importanceparagraph {
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
}

.closebutton {
  position: absolute;
  left: auto;
  top: 3%;
  right: 2%;
  bottom: auto;
}

.popupcontainer {
  position: relative;
  display: block;
  width: 700px;
  max-height: 700px;
  margin: auto;
  padding: 30px 50px 47px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 0.5fr 1.5fr 0.25fr;
  grid-template-columns: 0.5fr 1.5fr 0.25fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 19px 50px -17px #000;
}

.div-block-20 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.25fr 1fr;
  grid-template-columns: 0.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.purchaseblock {
  position: static;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -ms-grid;
  display: grid;
  height: auto;
  max-width: 1300px;
  margin-right: 20px;
  margin-bottom: 50px;
  margin-left: 20px;
  padding: 0px 10px;
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  grid-auto-columns: 1fr;
  grid-column-gap: 26px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.5fr 0.5fr 0.75fr;
  grid-template-columns: 0.5fr 0.5fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 10px;
  background-color: #fd0;
}

.image-14 {
  margin-top: -24px;
  margin-bottom: -1px;
}

.paymentbutton {
  display: -ms-grid;
  display: grid;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  grid-auto-columns: 1fr;
  grid-column-gap: 29px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 0.5fr;
  grid-template-columns: 1fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 20px 20px -16px #969696;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
}

.paymentbutton:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.paymentbuttontext {
  padding-right: 0px;
  font-size: 20px;
  line-height: 22px;
}

.purchaisecontainer {
  position: fixed;
  left: 0px;
  top: auto;
  right: 0px;
  bottom: 0%;
  padding-left: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(#fff));
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
}

.div-block-21 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.pricetext {
  font-size: 45px;
  line-height: 45px;
}

.text-block-8 {
  margin-right: 4px;
  padding-top: 5px;
  font-size: 18px;
}

.heading-2 {
  font-size: 20px;
  font-weight: 500;
}

.demotext {
  margin-top: 0px;
}

.paymentsystems {
  position: relative;
  top: 36px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -20px;
  margin-bottom: 0px;
  padding-top: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sll {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.text-block-9 {
  margin-left: 10px;
  color: #a5a5a5;
  font-size: 11px;
  line-height: 12px;
}

.div-block-22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-self: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.visa {
  width: 35px;
  margin-right: 15px;
}

.mastercard {
  width: 25px;
  margin-right: 10px;
}

.applepay {
  width: 40px;
  margin-right: 12px;
}

.mir {
  width: 40px;
  margin-right: 12px;
}

.text-block-10 {
  margin-bottom: 10px;
}

.demoheading {
  font-size: 20px;
  font-weight: 500;
}

.image-15 {
  width: 45px;
}

@media screen and (max-width: 991px) {
  .body {
    margin-top: 0px;
  }

  .columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .sidebar {
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .content {
    padding-right: 29px;
    padding-left: 34px;
  }

  .year {
    -ms-grid-columns: 0.25fr 1fr;
    grid-template-columns: 0.25fr 1fr;
  }

  .year-number-block {
    padding-bottom: 0px;
    padding-left: 15px;
  }

  .logo {
    margin-bottom: 0px;
  }

  .body-text {
    margin-top: 10px;
  }

  .pin-container {
    margin-top: 30px;
    margin-right: 0px;
    padding-top: 21px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .pin {
    white-space: nowrap;
  }

  .div-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    padding: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .logo-container-copy {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .div-block-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .survey-container {
    padding: 15px;
  }

  .everyyear {
    -ms-grid-columns: 0.25fr 1fr;
    grid-template-columns: 0.25fr 1fr;
  }

  .fixeddiv {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-top: -1px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 0.5fr 1fr;
    grid-template-columns: 0.5fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .disclaimer {
    width: 300px;
    margin-bottom: 14px;
    text-align: center;
  }

  .print-button {
    display: none;
  }

  .cta-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 250px;
    margin-top: 49px;
    padding: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .big-lead {
    width: 100%;
  }

  .emoji {
    font-size: 30px;
  }

  .cta-mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 15px;
    border-radius: 13px;
    box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.34);
  }

  .text-field-2 {
    margin-right: -4px;
  }

  .field-label-2 {
    text-align: center;
  }

  .leadgrid {
    grid-column-gap: 42px;
  }

  .featureheader {
    font-size: 16px;
  }

  .text-block-6 {
    font-size: 12px;
    line-height: 16px;
  }

  .background-video {
    height: 350px;
  }

  .div-block-17 {
    position: relative;
    display: block;
    width: 600px;
    padding-left: 30px;
  }

  .closebutton {
    position: absolute;
    left: auto;
    top: 2%;
    right: 2%;
    bottom: auto;
  }

  .popupcontainer {
    position: relative;
    display: block;
    width: 600px;
    padding-left: 30px;
  }

  .div-block-20 {
    position: static;
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 0.25fr 1fr;
    grid-template-columns: 0.25fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .purchaseblock {
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 30px;
    padding-left: 30px;
    grid-row-gap: 0px;
    grid-template-areas: ".";
    -ms-grid-columns: 0.5fr 0.75fr;
    grid-template-columns: 0.5fr 0.75fr;
    -ms-grid-rows: auto 0px auto;
    grid-template-rows: auto auto;
    border-radius: 0px;
    background-color: #e9e9e9;
  }

  .paymentbutton {
    margin-top: 0px;
    padding-left: 75px;
    background-color: #fd0;
  }

  .paymentsystems {
    top: 0px;
    margin-top: 0px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .sidebar {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .content {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .heading {
    margin-top: 0px;
    font-size: 30px;
  }

  .heading.bold {
    font-size: 24px;
    line-height: 28px;
  }

  .pin-container {
    margin-top: 20px;
    text-align: center;
  }

  .pin {
    text-align: center;
  }

  .text-field {
    width: 150px;
  }

  .home-content {
    width: 100%;
    padding: 40px;
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .div-block {
    padding-top: 0px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-2 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .survey-container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .everyyear {
    margin-left: 0px;
    padding-right: 20px;
  }

  .fixeddiv {
    margin-bottom: 0px;
  }

  .disclaimer {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .text-block-2 {
    margin-top: 0px;
  }

  .print-button {
    display: none;
  }

  .cta-button {
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .heading-landing {
    font-size: 42px;
    line-height: 45px;
  }

  .big-lead {
    width: 100%;
    font-size: 23px;
    line-height: 27px;
  }

  .image-3 {
    margin-left: -39px;
  }

  .report-features {
    margin-bottom: 0px;
  }

  .emoji {
    font-size: 33px;
    text-align: center;
  }

  .landing-div.grid {
    grid-column-gap: 30px;
  }

  .attention-block {
    padding: 39px;
  }

  .cta-mobile {
    z-index: 99999;
  }

  .smalltext {
    text-align: center;
  }

  .div-block-9 {
    width: 500px;
    height: 500px;
  }

  .cta-button-result {
    margin-top: 0px;
  }

  .leadgrid {
    grid-column-gap: 28px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .featuresblock {
    grid-column-gap: 0px;
  }

  .featurecontainer {
    -ms-grid-columns: 0.25fr 1.25fr;
    grid-template-columns: 0.25fr 1.25fr;
  }

  .div-block-12 {
    text-align: center;
  }

  .image-4 {
    margin-left: 185px;
  }

  .div-block-13 {
    margin-top: -291px;
    margin-left: -42px;
  }

  .payment {
    padding: 8px 8px 8px 21px;
    grid-column-gap: 15px;
  }

  .image-5 {
    width: auto;
    height: auto;
  }

  .paymenticon {
    margin-top: 5px;
  }

  .div-block-14 {
    padding-right: 17px;
    padding-left: 25px;
  }

  .image-7 {
    margin-top: 0px;
    margin-bottom: -19px;
    margin-left: -32px;
  }

  .importanceblock {
    grid-column-gap: 17px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .image-10 {
    margin-left: -3px;
  }

  .video-container {
    width: 100%;
  }

  .background-video {
    width: 100%;
    height: 280px;
  }

  .div-block-17 {
    width: 90%;
  }

  .popupcontainer {
    width: 90%;
  }

  .image-13 {
    display: none;
  }

  .purchaseblock {
    background-color: #f3f3f3;
  }

  .image-14 {
    margin-top: 28px;
  }

  .paymentbutton {
    padding-left: 30px;
  }

  .text-block-10 {
    margin-bottom: 0px;
  }

  .demoheading {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 479px) {
  .body {
    padding-top: 0px;
    padding-right: 10px;
    padding-left: 0px;
  }

  .sidebar {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .content {
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .year {
    padding-top: 10px;
    padding-bottom: 10px;
    grid-column-gap: 11px;
  }

  .year-number-block {
    padding-right: 0px;
    padding-left: 0px;
  }

  .age {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }

  .test-block {
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 10px;
  }

  .test-text {
    font-size: 15px;
    line-height: 18px;
  }

  .test-detail {
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  .heading {
    margin-top: 0px;
    font-size: 17px;
    line-height: 18px;
  }

  .body-text {
    margin-top: 10px;
    text-align: center;
  }

  .pin-container {
    margin-top: 20px;
    margin-right: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .pin {
    font-size: 28px;
    text-align: center;
  }

  .home-content {
    padding: 20px;
  }

  .div-block {
    width: auto;
    margin-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .survey-container {
    min-height: auto;
  }

  .everyyear {
    margin-left: 0px;
    padding: 0px 10px 20px;
    grid-column-gap: 11px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .disclamer {
    padding-right: 0px;
    padding-left: 0px;
  }

  .fixeddiv {
    margin: 2px;
    padding: 9px 9px 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .disclaimer {
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .text-block-2 {
    margin-top: 0px;
  }

  .print-button {
    display: none;
    padding: 24px;
  }

  .cta-button {
    width: 250px;
    margin-top: 56px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .div-block-5 {
    margin-left: 4px;
  }

  .heading-landing {
    margin-top: 0px;
    font-size: 28px;
    line-height: 32px;
  }

  .big-lead {
    font-size: 18px;
    line-height: 22px;
  }

  .report-block {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .image-3 {
    margin-left: auto;
  }

  .div-block-8 {
    margin-left: auto;
  }

  .landing-div.grid {
    grid-column-gap: 13px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .attention-block {
    padding: 21px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .small-text.gray {
    margin-bottom: 20px;
  }

  .privacy {
    padding: 32px 22px 32px 21px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .submit-button-2 {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .cta-mobile {
    height: 80px;
  }

  .desc {
    font-size: 12px;
  }

  .cta-button-result {
    display: none;
  }

  .navigation {
    padding: 0px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .leadgrid {
    grid-row-gap: 22px;
  }

  .pricetag {
    margin-top: 290px;
  }

  .image-4 {
    width: 180px;
    margin-top: -96px;
    margin-left: 1px;
  }

  .div-block-13 {
    margin-top: -449px;
    margin-left: -7px;
  }

  .payment {
    padding-top: 34px;
    padding-left: 5px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .image-5 {
    width: 100px;
  }

  .div-block-16 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .image-7 {
    margin-left: -15px;
    padding-right: 0px;
  }

  .desclamerblock {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .image-12 {
    width: 100px;
    margin-top: 48px;
    margin-left: 204px;
  }

  .background-video {
    height: 220px;
  }

  .div-block-17 {
    grid-auto-flow: row;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .testdatailpopup {
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  .agecard {
    font-size: 14px;
    line-height: 14px;
  }

  .popupcontainer {
    grid-auto-flow: row;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .image-13 {
    display: none;
  }

  .text-block-7 {
    margin-top: 20px;
    font-size: 12px;
    line-height: 15px;
  }

  .purchaseblock {
    padding-right: 10px;
    padding-left: 10px;
    grid-column-gap: 6px;
    -ms-grid-columns: 0.5fr 0.5fr;
    grid-template-columns: 0.5fr 0.5fr;
  }

  .image-14 {
    margin-top: 52px;
  }

  .paymentbutton {
    padding: 10px 15px;
    grid-column-gap: 10px;
  }

  .paymentbuttontext {
    font-size: 15px;
    line-height: 16px;
  }

  .pricetext {
    font-size: 35px;
  }

  .text-block-8 {
    font-size: 16px;
  }

  .demotext {
    margin-bottom: 8px;
    padding-bottom: 0px;
  }

  .paymentsystems {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .sll {
    display: none;
  }

  .visa {
    width: 40px;
    margin-right: 15px;
  }

  .mastercard {
    width: 28px;
    margin-right: 15px;
  }

  .applepay {
    width: 45px;
    margin-right: 15px;
  }

  .mir {
    width: 45px;
    margin-right: 15px;
  }

  .text-block-10 {
    font-size: 12px;
    line-height: 15px;
  }

  .demoheading {
    font-size: 15px;
    line-height: 18px;
  }

  .image-15 {
    width: 49px;
  }
}

#w-node-_33bbf7fb-d867-4526-794c-f7d7f5592f94-00ae4eac {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d647e7d-4e25-db88-466c-dd0a56fd3993-00ae4eac {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-d3cce4b6-d2f0-ff8a-60e3-196d6d8671a0-00ae4eac {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_108dc98f-206b-f0c7-d6a5-9f91bbf86491-00ae4eac {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f78168f0-cf66-aacc-2dbd-84fa5b8ddbbd-00ae4eac {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_796325f7-184b-58dd-ce27-a729aaa2d8c3-00ae4eac {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_8f0155fe-6632-a8a1-710c-785a97af8379-00ae4eac {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-d9358269-d34e-aa03-24c8-c16721a8f353-00ae4eac {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-cdb451d9-0dc5-dae8-a6c3-95157637825a-00ae4eac {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-d1458575-51ad-82bd-211c-dd38bfc6c5e9-00ae4eac {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_433c158a-dc7b-e654-316a-745df673492f-00ae4eac {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-b38ee00b-c5c1-ec1b-4f70-2177c154947d-00ae4eac {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_419b074c-55c0-cbc5-fed0-fdf7a69a6f6c-46f5b903 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2e904312-55a0-8eef-c587-80e1fe302c85-46f5b903 {
  -ms-grid-row: span 2;
  grid-row-start: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f9928956-0360-a16a-c11e-0d97b084f8d5-46f5b903 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-d9902d46-764d-f2a6-bb45-53d66152409f-46f5b903 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_66f79eed-4889-d80f-f7c3-2f0878ff4920-46f5b903 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_66f79eed-4889-d80f-f7c3-2f0878ff4922-46f5b903 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_1072cd3e-cdf4-ff9c-efb2-9d47c71f84f8-46f5b903 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_1072cd3e-cdf4-ff9c-efb2-9d47c71f84fa-46f5b903 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-cf412f58-e047-02f0-e8c4-63f49a12117c-46f5b903 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-cf412f58-e047-02f0-e8c4-63f49a12117e-46f5b903 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_43d19281-46bb-235b-84ba-3b96c6e28639-46f5b903 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_43d19281-46bb-235b-84ba-3b96c6e2863b-46f5b903 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-e682cc7c-2094-dbb0-fd66-e69839906ee5-46f5b903 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-e682cc7c-2094-dbb0-fd66-e69839906ee7-46f5b903 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_90ad4467-b300-a176-bb58-fe5f60b18a3b-46f5b903 {
  -ms-grid-row: span 3;
  grid-row-start: span 3;
  -ms-grid-row-span: 3;
  grid-row-end: span 3;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_16e16ae0-ec29-5e17-f646-8896ffe44a66-46f5b903 {
  -ms-grid-column-align: center;
  justify-self: center;
}

@media screen and (max-width: 991px) {
  #w-node-d3cce4b6-d2f0-ff8a-60e3-196d6d8671a0-00ae4eac {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_419b074c-55c0-cbc5-fed0-fdf7a69a6f6c-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_2e904312-55a0-8eef-c587-80e1fe302c85-46f5b903 {
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_19dc4d12-d9ef-d903-5b1b-425b43c8f517-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_4a30e0a5-0853-4fcc-42e8-b37fbf820cf4-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_3bdeb84f-a87d-4eda-6710-227786752584-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-aa99d17f-8d1c-6249-0c58-eaf69c9f946c-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_1624e3c9-fcc5-6312-fbb2-f95190db6157-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_90ad4467-b300-a176-bb58-fe5f60b18a3b-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-abd47a99-2c90-3158-d9c2-01bf233e5ebe-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_3aafce8c-2938-93b4-a006-f33b1ff95c09-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-ac0388b1-83c8-b129-68e9-b446c297bfca-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-ef85c3d9-759e-8fe7-7aad-5a0ac407b0dc-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_92563a48-fd75-6267-e63b-b4e8d716528d-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_36cdd141-58e6-9378-6d0d-ad0d755437ab-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_90ad4467-b300-a176-bb58-fe5f60b18a3b-46f5b903 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }
}




@font-face {
  font-family: 'Stem';
  src: url('../fonts/Stem-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Stem';
  src: url('../fonts/Stem-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Stem';
  src: url('../fonts/Stem-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.noPin {
  margin-bottom: 10px;
  color: #EF001B;
}

body {
  background-color: #f0f0f0;
}

.cta-button {
  text-decoration: none !important;

}

.cta-header {
  padding: 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-decoration: none !important;
  color: black !important;
}

.loading-container{
  z-index: 9999;
}

.cta-header-result {
  padding: 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-decoration: none !important;
  color: black !important;
}

.cta-mobile{
  z-index: 9999999;
}

.cta-button-result{
  text-decoration: none !important;
}

.cta-button-result-text {
  text-decoration: none !important;
}